<template>
   <div style="background-image: url('/background2.jpg');background-op" class="image-container bg-cover bg-center min-h-screen">
     <div class="absolute inset-x-0 -z-10 transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
       <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#fc2e02] to-[#fc0202] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
     </div>
     <div class="grid grid-cols-2 m-10 md:grid-cols-4 gap-2">
       <a v-for="(supplier,index) in suppliers" :key="index" :href="supplier.url" target="_blank">
          <div class="bg-white p-6 rounded-lg h-[400px]">
            <img class="h-auto max-w-full rounded-lg"
                 :src="supplier.img" alt="">
          </div>
         <h2 class="text-xl">{{supplier.title}}</h2>
       </a>
     </div>
     <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
       <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fc2e02] to-[#fc0202] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
     </div>

   </div>
</template>

<script>
export default {
  name: 'SupplierPage',
  props: {
    msg: String
  },
  data(){
    return {
      suppliers: [
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/2023_EM%20Catalogue.pdf",
          "title": "2023 EM Catalogue",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-A-Motor%20Control%20_%20drives.pdf",
          "title": "2022-23-A Motor Control & Drives",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-B-Power%20distribution%20_%20protection.pdf",
          "title": "2022-23-B Power Distribution & Protection",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-C-Automation.pdf",
          "title": "2022-23-C Automation",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-D-Control%20and%20signalling.pdf",
          "title": "2022-23-D Control and Signalling",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2020-E-Metering.pdf",
          "title": "2020-E Metering",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-F-Power%20quality.pdf",
          "title": "2022-23-F Power Quality",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-G-Communication.pdf",
          "title": "2022-23-G Communication",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-H-Enclosures%20_%20climate%20control.pdf",
          "title": "2022-23-H Enclosures & Climate Control",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-I-Plugs%20_%20Sockets.pdf",
          "title": "2022-23-I Plugs & Sockets",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-J-Timers%20_%20control%20relays.pdf",
          "title": "2022-23-J Timers & Control Relays",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-22-K-Field%20switching%20and%20Sensing.pdf",
          "title": "2022-22-K Field Switching and Sensing",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-L-Termination%20and%20wiring.pdf",
          "title": "2022-23-L Termination and Wiring",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-M-Tools.pdf",
          "title": "2022-23-M Tools",
          "img": "em.png"
        },
        {
          "url": "https://www.em.co.za/site/Catalogue%20PDFs/Catalogue%20Sections%20for%20Website/2022-23-N-Safety.pdf",
          "title": "2022-23-N Safety",
          "img": "em.png"
        }
      ]
    }
  }
}
</script>

