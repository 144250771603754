<template>
  <div id="app">
    <Default></Default>
    <router-view></router-view>
  </div>
</template>

<script>

import Default from "@/Views/Default.vue";
export default {
  name: 'App',
  components: {
    Default
  }
}
</script>

<style>
  #app {
    color: black;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin-top: 60px;
  }
</style>


