<template>
   <div style="background-image: url('/background.png')" class="bg-cover bg-center min-h-screen">
     <div class="relative isolate">
       <div class="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
         <div class="relative px-6  lg:static lg:px-8">
           <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
             <h2 class="text-3xl font-bold tracking-tight text-gray-900">Contact Us</h2>
             <dl class="mt-10 space-y-2 text-base leading-7 text-gray-600 bg-white border-2 rounded-lg p-6">
               <div class="flex mb-10">
                 <dt class="flex-none">
                   <span class="sr-only">Address</span>
                   <svg class="w-[48px] h-[48px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z"/>
                   </svg>
                 </dt>
                 <dd>
                   <p>
                     <a :href="'https://www.google.com/maps/dir/?api=1&destination=159 Edison Crescent, Hennops Park, Centurion, 0046&travelmode=driving'" target="_blank" color="primary" label="Get Directions">
                       159 Edison Crescent, Hennops Park, Centurion, 0046
                     </a>
                   </p>
                 </dd>
               </div>
               <div class="flex">
                 <dt class="flex-none">
                   <span class="sr-only">Email:</span>
                   <svg class="w-[21px] h-[21px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <path stroke="currentColor" stroke-linecap="round" stroke-width="1.9" d="m3.5 5.5 7.9 6c.4.3.8.3 1.2 0l7.9-6M4 19h16c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H4a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Z"/>
                   </svg>
                 </dt>
                 <dd class="ml-8"><a class="hover:text-gray-900" href="mailto:info@zetty.co.za">info@zetty.co.za</a></dd>
               </div>
               <div class="flex">
                 <dt class="flex-none">
                   <span class="sr-only">Telephone</span>
                   <svg class="w-[21px] h-[21px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9" d="m18.4 14.8-1.2-1.3a1.7 1.7 0 0 0-2.4 0l-.7.7a1.7 1.7 0 0 1-2.4 0l-1.9-1.9a1.7 1.7 0 0 1 0-2.4l.7-.6a1.7 1.7 0 0 0 0-2.5L9.2 5.6a1.6 1.6 0 0 0-2.4 0c-3.2 3.2-1.7 6.9 1.5 10 3.2 3.3 7 4.8 10.1 1.6a1.6 1.6 0 0 0 0-2.4Z"/>
                   </svg>
                 </dt>
                 <dd class="ml-8">
                   <a class="hover:text-gray-900" href="tel:+27 12 653 5170">
                     012 653 5170
                   </a>
                 </dd>
               </div>
               <div class="flex">
                 <dt class="flex-none">
                   <span class="sr-only">Telephone</span>
                   <svg class="w-[21px] h-[21px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9" d="m18.4 14.8-1.2-1.3a1.7 1.7 0 0 0-2.4 0l-.7.7a1.7 1.7 0 0 1-2.4 0l-1.9-1.9a1.7 1.7 0 0 1 0-2.4l.7-.6a1.7 1.7 0 0 0 0-2.5L9.2 5.6a1.6 1.6 0 0 0-2.4 0c-3.2 3.2-1.7 6.9 1.5 10 3.2 3.3 7 4.8 10.1 1.6a1.6 1.6 0 0 0 0-2.4Z"/>
                   </svg>
                 </dt>
                 <dd class="ml-8">
                   <a class="hover:text-gray-900" href="tel:+27 12 653 8136">
                     012 653 8136
                   </a>
                 </dd>
               </div>
               <div class="flex">
                 <dt class="flex-none">
                   <span class="sr-only">Telephone</span>
                   <svg class="w-[21px] h-[21px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9" d="m18.4 14.8-1.2-1.3a1.7 1.7 0 0 0-2.4 0l-.7.7a1.7 1.7 0 0 1-2.4 0l-1.9-1.9a1.7 1.7 0 0 1 0-2.4l.7-.6a1.7 1.7 0 0 0 0-2.5L9.2 5.6a1.6 1.6 0 0 0-2.4 0c-3.2 3.2-1.7 6.9 1.5 10 3.2 3.3 7 4.8 10.1 1.6a1.6 1.6 0 0 0 0-2.4Z"/>
                   </svg>
                 </dt>
                 <dd class="ml-8">
                   <a class="hover:text-gray-900" href="tel:+27 12 653 8134">
                     012 653 8134
                   </a>
                 </dd>
               </div>
               <div class="flex">
                 <dt class="flex-none">
                   <span class="sr-only">Cellphone</span>
                   <svg class="w-[21px] h-[21px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9" d="m18.4 14.8-1.2-1.3a1.7 1.7 0 0 0-2.4 0l-.7.7a1.7 1.7 0 0 1-2.4 0l-1.9-1.9a1.7 1.7 0 0 1 0-2.4l.7-.6a1.7 1.7 0 0 0 0-2.5L9.2 5.6a1.6 1.6 0 0 0-2.4 0c-3.2 3.2-1.7 6.9 1.5 10 3.2 3.3 7 4.8 10.1 1.6a1.6 1.6 0 0 0 0-2.4Z"/>
                   </svg>
                 </dt>
                 <dd class="ml-8">
                   <a class="hover:text-gray-900" href="tel:+27 82 416 4966">
                     082 416 4966
                    </a>
                 </dd>
               </div>
             </dl>
           </div>
         </div>
         <div>
           <div class="min-w-max h-screen">
             <div id="embed-ded-map-canvas" style="height:100%; width:100%;max-width:100%;">
               <iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Zetty+Electrical+Wholesale,+Edison+Crescent,+Hennopspark,+Centurion,+South+Africa&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
               </iframe>
             </div>
         </div>
       </div>
     </div>
   </div>
   </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>

