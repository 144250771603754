<template>
   <div style="background-image: url('/background.png')" class="bg-cover bg-center min-h-screen">
     <div class="relative isolate lg:px-8 mt-10">

       <div class="mx-auto max-w-2xl">
         <div class="text-center">
           <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Welcome to Zetty Electrical Wholesalers</h1>
           <p class="mt-6 bg-white bg-opacity-75 rounded-lg px-4 py-4 text-lg leading-8 text-gray-700">
             With years of experience under our belt, we take pride in offering an unparalleled selection of products to meet all your electrical needs. At Zetty, we understand the importance of quality service and quick turnaround times. Our team of experts is dedicated to providing you with the best solutions, ensuring your projects are completed without delay. Choose Zetty for reliability, expertise, and a vast product variety, all designed to keep your operations running smoothly.
             </p>
         </div>
       </div>

     </div>
   </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>

