<template>
   <div style="background-image: url('/background2.jpg')" class="bg-cover bg-center min-h-screen">
      <div class="bg-gray-100 mb-6 rounded-lg w-128">
          Terms and conditions
      </div>
     <div class="min-h-screen h-screen">
       <iframe width="100%" height="80%" src="/Terms_and_Conditions_of_Sale_and_Tender_v2.4.pdf">
       </iframe>
     </div>

   </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>

