import { createRouter, createWebHistory } from 'vue-router';
import Home from './Views/Home.vue';
import Suppliers from './Views/Suppliers.vue';
import Terms from './Views/Terms.vue';
import ContactUs from "@/Views/ContactUs.vue";
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/suppliers',
        name: 'Suppliers',
        component: Suppliers,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactUs,
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
    },
    // Add more routes here
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;